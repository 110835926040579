<template>
  <div>
    <div id="lunaNavBox">
      <img
        height="25"
        src="@/assets/images/moonarch-logo.png"
        style="padding: 20px; cursor: pointer"
        @click="navLuna('search')"
      />
      <div class="buttonLight newSearchBtn" @click="navLuna('search')">
        <!-- :class="[this.currentResult == 'search' ? 'selectedTableButton' : '']" -->

        <!-- @keydown.enter="navLuna('search')" -->
        <div class="flexLeftRow">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="22"
            height="22"
            color="#000000"
            fill="none"
          >
            <path
              d="M2.5 12C2.5 7.52166 2.5 5.28249 3.89124 3.89124C5.28249 2.5 7.52166 2.5 12 2.5C16.4783 2.5 18.7175 2.5 20.1088 3.89124C21.5 5.28249 21.5 7.52166 21.5 12C21.5 16.4783 21.5 18.7175 20.1088 20.1088C18.7175 21.5 16.4783 21.5 12 21.5C7.52166 21.5 5.28249 21.5 3.89124 20.1088C2.5 18.7175 2.5 16.4783 2.5 12Z"
              stroke="currentColor"
              stroke-width="1.6"
            />
            <path
              d="M16 12H8M16 12C16 11.2998 14.0057 9.99153 13.5 9.5M16 12C16 12.7002 14.0057 14.0085 13.5 14.5"
              stroke="currentColor"
              stroke-width="1.6"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <span style="margin-left: 10px">New Search</span>
        </div>
        <!-- <div class="flexRightRow">
          <div class="buttonLight-design" style="width: 11px">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="14"
              height="14"
              color="grey"
              fill="none"
            >
              <path
                d="M15 9V15H9V9H15Z"
                stroke="currentColor"
                stroke-width="2"
                stroke-linejoin="round"
              />
              <path
                d="M15 15H18C19.6569 15 21 16.3431 21 18C21 19.6569 19.6569 21 18 21C16.3431 21 15 19.6569 15 18V15Z"
                stroke="currentColor"
                stroke-width="2"
                stroke-linejoin="round"
              />
              <path
                d="M9 15.002H6C4.34315 15.002 3 16.3451 3 18.002C3 19.6588 4.34315 21.002 6 21.002C7.65685 21.002 9 19.6588 9 18.002V15.002Z"
                stroke="currentColor"
                stroke-width="2"
                stroke-linejoin="round"
              />
              <path
                d="M15 9L15 6C15 4.34315 16.3431 3 18 3C19.6569 3 21 4.34315 21 6C21 7.65685 19.6569 9 18 9H15Z"
                stroke="currentColor"
                stroke-width="2"
                stroke-linejoin="round"
              />
              <path
                d="M9 9V6C9 4.34315 7.65685 3 6 3C4.34315 3 3 4.34315 3 6C3 7.65685 4.34315 9 6 9H9Z"
                stroke="currentColor"
                stroke-width="2"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <div
            class="buttonLight-design"
            style="width: 11px; font-size: 11px; height: 14px; color: grey"
          >
            K
          </div>
        </div> -->
      </div>
      <div
        v-if="prevSearches.length != 0"
        class="newGreyTitle"
        style="margin-top: 20px; margin-bottom: 10px; margin-left: 10px"
      >
        Previous Searches
      </div>
      <div
        v-else
        class="newGreyTitle"
        style="margin-top: 20px; margin-bottom: 10px; margin-left: 10px"
      >
        No previous searches yet
      </div>
      <div class="lunaNavScroll">
        <div v-for="(prevSearch, index) in prevSearches" :key="index">
          <div
            v-if="!prevSearch.DELETED && !prevSearch.DEPRECATED"
            class="buttonClear lunaTab"
            :class="[
              this.currentResult.LINKEDIN == prevSearch.LINKEDIN
                ? 'selectedTableButton'
                : '',
            ]"
            @click="navLuna(prevSearch, index)"
          >
            <div class="flexLeftRow">
              <div
                class="roundLogo"
                style="border-radius: 7px; height: 20px; width: 20px"
              >
                <img :src="prevSearch.IMAGE" height="20" width="20" />
              </div>
              <div
                style="
                  margin-left: 10px;
                  overflow: hidden;
                  text-overflow: ellipsis !important;
                  white-space: nowrap;
                  max-width: 140px;
                "
              >
                {{ prevSearch.NAME }}
              </div>
              <div
                v-if="
                  this.currentResult.LINKEDIN == prevSearch.LINKEDIN &&
                  this.loadingFounders
                "
                class="turningAnimation"
                style="margin-left: 5px"
              >
                <svg width="15" height="15" viewBox="0 0 97 97" fill="none">
                  <path
                    d="M7 48.5C7 25.5802 25.5802 7 48.5 7"
                    stroke="black"
                    stroke-width="10"
                    stroke-linecap="round"
                  />
                </svg>
              </div>
              <div
                style="margin-left: 5px; height: 15px"
                v-else-if="
                  this.currentResult.LINKEDIN != prevSearch.LINKEDIN &&
                  !prevSearch.CURRENT_VERSION
                "
              >
                <Icon img="error" hexCode="FF0000" size="15" stroke="1.5" />
              </div>
            </div>
            <div
              v-if="this.isAdmin"
              class="deleteSearch"
              @click="removeFromSearch(prevSearch)"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24"
                viewBox="0 -960 960 960"
                width="24"
              >
                <path
                  d="M480-451.692 270.154-241.846q-5.615 5.615-13.769 6-8.154.385-14.539-6T235.461-256q0-7.769 6.385-14.154L451.692-480 241.846-689.846q-5.615-5.615-6-13.769-.385-8.154 6-14.539T256-724.539q7.769 0 14.154 6.385L480-508.308l209.846-209.846q5.615-5.615 13.769-6 8.154-.385 14.539 6T724.539-704q0 7.769-6.385 14.154L508.308-480l209.846 209.846q5.615 5.615 6 13.769.385 8.154-6 14.539T704-235.461q-7.769 0-14.154-6.385L480-451.692Z"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      id="tableBox"
      class="boxShadow"
      :class="this.lunaStage == 0 ? 'centeredTableBox' : ''"
    >
      <div v-if="this.lunaStage == 0" class="resultFont">
        Evaluate any company
      </div>
      <div
        id="searcher"
        :class="[
          this.lunaStage == 0 ? 'searcherStart' : 'searcherTop',
          this.onSearch ? 'selectedSearch' : '',
        ]"
        @click="selectedSearcher()"
        @keydown.enter="submitSearch()"
      >
        <svg
          v-if="this.lunaStage != 0"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="18"
          height="18"
          color="grey"
          fill="none"
        >
          <path
            d="M17.5 17.5L22 22"
            stroke="currentColor"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M20 11C20 6.02944 15.9706 2 11 2C6.02944 2 2 6.02944 2 11C2 15.9706 6.02944 20 11 20C15.9706 20 20 15.9706 20 11Z"
            stroke="currentColor"
            stroke-width="3"
            stroke-linejoin="round"
          />
        </svg>
        <!-- <svg
          v-if="this.lunaStage != 0"
          width="20"
          height="20"
          viewBox="0 0 14 14"
          fill="grey"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.49531 10.9876C3.96077 10.9876 2.66125 10.4555 1.59676 9.39127C0.532254 8.32706 0 7.0279 0 5.49378C0 3.95967 0.532254 2.66051 1.59676 1.59629C2.66125 0.532098 3.96077 0 5.49531 0C7.02985 0 8.32938 0.532098 9.39388 1.59629C10.4584 2.66051 10.9906 3.95967 10.9906 5.49378C10.9906 6.1353 10.8829 6.74803 10.6675 7.33196C10.452 7.91589 10.1646 8.42381 9.80515 8.85571L13.809 12.8581C13.9334 12.9825 13.997 13.1388 13.9999 13.3271C14.0028 13.5154 13.9392 13.6746 13.809 13.8048C13.6788 13.9349 13.5209 14 13.3355 14C13.15 14 12.9922 13.9349 12.862 13.8048L8.85818 9.80243C8.40887 10.1733 7.89217 10.4635 7.30808 10.6731C6.72398 10.8828 6.11972 10.9876 5.49531 10.9876ZM5.49531 9.64007C6.65314 9.64007 7.63384 9.2384 8.4374 8.43506C9.24097 7.63171 9.64275 6.65129 9.64275 5.49378C9.64275 4.33628 9.24097 3.35585 8.4374 2.55251C7.63384 1.74917 6.65314 1.34749 5.49531 1.34749C4.33748 1.34749 3.35679 1.74917 2.55322 2.55251C1.74967 3.35585 1.34789 4.33628 1.34789 5.49378C1.34789 6.65129 1.74967 7.63171 2.55322 8.43506C3.35679 9.2384 4.33748 9.64007 5.49531 9.64007Z"
          />
        </svg> -->
        <span style="margin-left: 10px">
          <input
            class="lunaSearchBox"
            :class="this.lunaStage == 0 ? 'lunaSearchBoxStart' : ''"
            v-model="lunaSearch"
            @focus="onSearch = true"
            @blur="onSearch = false"
            placeholder="Search with linkedin url or company name"
            type="text"
            maxlength="100"
            autocomplete="off"
            id="searchTextBox"
          />
        </span>
        <div class="buttonBlue" @click="submitSearch()" style="min-width: 0px">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
            color="#ffffff"
            fill="none"
          >
            <path
              d="M20 12L4 12"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M15 17C15 17 20 13.3176 20 12C20 10.6824 15 7 15 7"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <!-- <svg
            xmlns="http://www.w3.org/2000/svg"
            height="20"
            viewBox="0 -960 960 960"
            width="20"
            fill="white"
          >
            <path
              d="M665.078-450.001H210.001q-12.769 0-21.384-8.615-8.616-8.615-8.616-21.384t8.616-21.384q8.615-8.615 21.384-8.615h455.077l-206.77-206.77q-8.923-8.923-8.807-20.884.115-11.962 9.423-21.269 9.307-8.692 21.076-9 11.769-.308 21.076 9l253.615 253.615q5.615 5.615 7.923 11.846 2.308 6.23 2.308 13.461t-2.308 13.461q-2.308 6.231-7.923 11.846L501.076-201.078q-8.307 8.308-20.576 8.5-12.269.192-21.576-8.5-9.308-9.307-9.308-21.384t9.308-21.384l206.154-206.155Z"
            />
          </svg> -->
        </div>
      </div>
      <div v-if="lunaStage == 0" class="exampleCompanyGrid">
        <div class="newGreyTitle exampleCompanyText">
          Run example companies:
        </div>
        <div
          class="buttonLight exampleCompany"
          @click="submitExample('https://www.linkedin.com/company/mercuryhq/')"
        >
          <div
            class="roundLogo"
            style="border-radius: 7px; height: 25px; width: 25px"
          >
            <img
              height="25"
              width="25"
              src="https://media.licdn.com/dms/image/C560BAQE5xRfcu5hzHQ/company-logo_200_200/0/1630581609991/mercurybank_logo?e=2147483647&v=beta&t=aIYzXbAe0ko1s5xSHpR1CijSR5fVtgGdM0hgWqJnNaA"
            />
          </div>
          <div style="margin-left: 10px">Mercury</div>
        </div>
        <div
          class="buttonLight exampleCompany"
          @click="
            submitExample('https://www.linkedin.com/company/perplexity-ai/')
          "
        >
          <div
            class="roundLogo"
            style="border-radius: 7px; height: 25px; width: 25px"
          >
            <img
              height="25"
              width="25"
              src="https://firebasestorage.googleapis.com/v0/b/moonarch-portal.appspot.com/o/lunaImgs%2FzORjHxBtUpODWkjHxkZi?alt=media&token=5bbeaeae-5f15-4f50-83e2-5bea4bcafb03"
            />
          </div>
          <div style="margin-left: 10px">Perplexity</div>
        </div>
        <div
          class="buttonLight exampleCompany"
          @click="submitExample('https://www.linkedin.com/company/deel/')"
        >
          <div
            class="roundLogo"
            style="border-radius: 7px; height: 25px; width: 25px"
          >
            <img
              height="25"
              width="25"
              src="https://media.licdn.com/dms/image/D4E0BAQES3O6oszqhug/company-logo_200_200/0/1708945543116/deel_logo?e=2147483647&v=beta&t=yw_VtCvbSBBOomnKwvdtSLaxf5bnUHfms0nr26yaQUE"
            />
          </div>
          <div style="margin-left: 10px">Deel</div>
        </div>
        <div
          class="buttonLight exampleCompany"
          @click="submitExample('https://www.linkedin.com/company/moonarch-io')"
        >
          <div
            class="roundLogo"
            style="border-radius: 7px; height: 25px; width: 25px"
          >
            <img
              height="18"
              width="18"
              src="@/assets/images/moonarchSmall.png"
            />
          </div>
          <div style="margin-left: 10px">Moonarch</div>
        </div>
        <!-- <div class="greyTitle" style="margin: 0">or quicksearch a company:</div> -->
        <!-- <div class="flexLeftRow">
          <div class="suggestedCompany">coming soon</div>
        </div> -->
      </div>
      <div v-if="lunaStage == 1" class="lunaView">
        <SearchResults
          :searchResults="this.searchResults"
          :lunaSearch="this.lunaSearch"
          :stage="this.stage"
          :industryCipher="this.industryCipher"
          :aiCipher="this.aiCipher"
          :isAdmin="this.isAdmin"
          @navLuna="navLuna"
          @openStartup="openStartup"
        />
      </div>
      <div v-if="lunaStage == 2" class="lunaView">
        <EditCompany
          :targetStartup="this.targetStartup"
          :stage="this.stage"
          :industryCipher="this.industryCipher"
          :apiKey="this.apiKey"
          @goToStage="goToStage"
          @runModel="runModel"
        />
      </div>
      <div v-if="lunaStage == 3" class="lunaView">
        <RunningModel
          :targetStartup="this.targetStartup"
          :startupFounders="this.startupFounders"
          :industryCipher="this.industryCipher"
          :stage="this.stage"
          :apiKey="this.apiKey"
          @completedModelRun="completedModelRun"
        />
        <!-- :dataForModel="this.dataForModel" -->
        <!-- :targetFunding="this.targetFunding"
          :targetRevenue="this.targetRevenue"
          :targetStage="this.targetStage"
          :targetIndustry="this.targetIndustry" -->
      </div>
      <div v-if="lunaStage == 4" class="lunaView">
        <Output
          :key="outputKey"
          :targetStartup="this.targetStartup"
          :dataForModel="this.dataForModel"
          :industryCipher="this.industryCipher"
          :lunaConfidence="this.lunaConfidence"
          :wholeStartup="this.wholeStartup"
          :stage="this.stage"
          :apiKey="this.apiKey"
        />
        <!-- v-if="reloadOutput" -->
        <!-- :targetFunding="this.targetFunding"
          :targetRevenue="this.targetRevenue"
          :targetLocation="this.targetLocation"
          :targetStage="this.targetStage"
          :targetIndustry="this.targetIndustry" -->
      </div>
      <!-- <div>
        <button @click="checkStartupFromDB()">click me to run a company</button>
      </div> -->
    </div>
    <div v-if="loadingFounders" class="loadingFounderCSS">
      <div class="loadingGif">
        <svg
          class="loadingGifComponent"
          viewBox="0 0 1272 1272"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M930 781C930 1019.03 737.035 1212 499 1212C260.965 1212 68 1019.03 68 781C68 542.965 260.965 350 499 350C602.835 350 698.093 386.718 772.5 447.879L970.307 249.5C959.311 230.89 953 209.182 953 186C953 116.964 1008.96 61 1078 61C1147.04 61 1203 116.964 1203 186C1203 255.036 1147.04 311 1078 311C1055.64 311 1034.66 305.131 1016.5 294.85L819.656 493C888.259 569.331 930 670.291 930 781ZM422 618C470.049 618 509 579.049 509 531C509 482.951 470.049 444 422 444C373.951 444 335 482.951 335 531C335 579.049 373.951 618 422 618ZM746 942C794.049 942 833 903.049 833 855C833 806.951 794.049 768 746 768C697.951 768 659 806.951 659 855C659 903.049 697.951 942 746 942Z"
          />
        </svg>
      </div>
      <div style="margin-top: 20px">Extracting founder insights...</div>
      <!-- <LoadingAnimation hideLogo="true" /> -->
    </div>
    <div v-if="loadingFoundersError" class="loadingFounderCSS">
      <svg
        width="70"
        height="70"
        viewBox="0 0 1312 1312"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="649" cy="656" r="656" fill="#02001A" />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M730 1013C730 1251.03 537.035 1444 299 1444C60.9653 1444 -132 1251.03 -132 1013C-132 774.965 60.9653 582 299 582C402.835 582 498.093 618.718 572.5 679.879L770.307 481.5C759.311 462.89 753 441.182 753 418C753 348.964 808.964 293 878 293C947.036 293 1003 348.964 1003 418C1003 487.036 947.036 543 878 543C855.644 543 834.658 537.131 816.5 526.85L619.656 725C688.259 801.331 730 902.291 730 1013ZM303.87 731.121V794.749H253.75V844.87H190.121V794.749H140V731.121H190.121V681H253.75V731.121H303.87ZM626.87 1120.75V1057.12H576.75V1007H513.121V1057.12H463V1120.75H513.121V1170.87H576.75V1120.75H626.87Z"
          fill="white"
        />
      </svg>
      <div style="margin-top: 20px">Sorry, could not update founders.</div>
    </div>
  </div>
</template>

<script>
import Icon from "@/components/utilities/Icon.vue";
// import LoadingAnimation from "@/components/utilities/LoadingAnimation.vue";
import axios from "axios";
import industryCipher from "@/assets/ciphers/industryCipher.json";
import aiCipher from "@/assets/ciphers/aiIndustries.json";
import SearchResults from "@/views/pageViews/luna-searchresult.vue";
import EditCompany from "@/views/pageViews/luna-editcompany.vue";
import Output from "@/views/pageViews/luna-output.vue";
import RunningModel from "@/views/pageViews/luna-runningmodel.vue";
import { db, auth } from "@/main.js";
import {
  getDoc,
  getDocs,
  doc,
  addDoc,
  query,
  where,
  collection,
  updateDoc,
} from "@firebase/firestore";
import logAction from "@/trackers.js";
// import { ref } from "vue";

export default {
  name: "Luna",
  components: {
    SearchResults,
    EditCompany,
    Output,
    RunningModel,
    Icon,
    // LoadingAnimation,
  },
  setup() {
    return {};
  },
  updated() {
    // console.log("re-initialize data");
    // console.log(this.$route.params.companyID);
    // console.log(this.searchIDs);
    // console.log(
    //   this.prevSearches[this.searchIDs.indexOf(this.$route.params.companyID)]
    // );
    // this.wholeStartup
    // let cur_index = this.prevSearches.indexOf(this.wholeStartup);
    // let route_index = this.$route.params.companyID
    //   ? this.searchIDs.indexOf(this.$route.params.companyID)
    //   : -1;
    // console.log(cur_index);
    // console.log(route_index);
    // this.$route.params.companyID &&
    // if (cur_index != route_index) {
    //   this.navLuna(this.prevSearches[route_index], route_index);
    // }
  },
  data() {
    // let user = auth.currentUser;
    // console.log("re-initialize data");
    this.getSaved(auth.currentUser);
    return {
      user: auth.currentUser,
      isAdmin: false,
      industryCipher: industryCipher,
      aiCipher: aiCipher,
      confirmedEditPage: false,
      loadingstage: false,
      lunaSearch: "",
      onSearch: false,
      currentResult: "search",
      searchResults: [],
      lunaStage: 0,
      targetRevenue: 0,
      targetFunding: 0,
      targetIndustry: 0,
      targetStage: 0,
      targetLocation: "",
      targetStartup: null,
      startupFounders: null,
      lunaConfidence: 0,
      reloadOutput: true,
      outputKey: 0,
      apiKey: "Bearer ",
      stage: [
        "PreSeed",
        "Seed",
        "Series A",
        "Series B",
        "Series C+",
        "Unknown",
      ],
      prevSearches: [],
      searchIDs: [],
      dataForModel: {
        founder: [],
        company_name: "",
        year_founded: "",
        month_founded: 0, //CHANGE THIS LATER
        industry: "",
      },
      wholeStartup: null,
      loadingFounders: false,
      loadingFoundersError: false,
    };
  },
  computed: {},
  methods: {
    /**
     * get startup that user clicked on from the search result lists and set it as target startup
     * @param {*} target
     */
    openStartup(target) {
      this.targetStartup = target;
      this.lunaStage = 2;
    },
    async tempFunc() {
      // console.log("starting temp 2");
      // const temp = query(
      //   collection(db, "industries"),
      //   where("JSON", "!=", null)
      // );
      // const temp = query(
      //   collection(db, "luna_results"),
      //   where("USER", "not-in", [
      //     "8ldSPIob9JMv6pVOmAwMIAkZJV02",
      //     "qUt5TgxXcqhLlfXwYpFi9a9hRsp1",
      //     "1LSJb6lXjiX68CgsuOx0JE74EoN2",
      //     "WjQuBmvwjpXx3pEY40J7Y8uVAEQ2",
      //     "j0kespZy72PmBI24IsJUNVYfZBY2",
      //     "ia6gOkyBBxX3g9cUDC7rMLzO3Tw2",
      //     "18KRVjuWRSYo1FlVZJ1LqxbQp872",
      //     "FZoFonDr5OYUyl31pwCPFSClBIm2",
      //     "Xu31YKoBFURN1neGSqbV40yiDQc2",
      //     "yfSclOaSDefpHFdghC65LzRdUln2",
      //   ])
      // );
      // const temp_snap = await getDocs(temp);
      // temp_snap.forEach((doc) => {
      //   console.log(
      //     doc.data().LINKEDIN +
      //       " " +
      //       doc.data().TARGETSTARTUP.industry +
      //       " " +
      //       doc.data().USER +
      //       " " +
      //       doc.data().CONFIDENCE +
      //       " " +
      //       doc.data().TIMESTAMP
      //   );
      //   return;
      // });
      // console.log("ending temp");
    },
    /**
     * save luna result to firebase db
     * @param {*} itemToAdd
     */
    async completedModelRun(itemToAdd) {
      const docRef = await addDoc(collection(db, "luna_results"), itemToAdd);
      this.searchIDs.unshift(docRef.id);
      // await db.collection("luna_results").add(itemToAdd);
      this.prevSearches.push(itemToAdd);
      this.prevSearches.sort((a, b) => b.TIMESTAMP - a.TIMESTAMP);
      this.navLuna(itemToAdd, 0);
    },
    /**
     * delete a luna result from feed
     * @param {*} prevSearch
     */
    async removeFromSearch(prevSearch) {
      let tempID = "";

      const user_ref = query(
        collection(db, "luna_results"),
        where("USER", "==", this.user.uid),
        where("TIMESTAMP", "==", prevSearch.TIMESTAMP),
        where("NAME", "==", prevSearch.NAME)
      );

      const user_snapshot = await getDocs(user_ref);

      user_snapshot.forEach((doc) => {
        tempID = doc.id;
        return;
      });

      // await db
      //   .collection("luna_results")
      //   .where("USER", "==", this.user.uid)
      //   .where("TIMESTAMP", "==", prevSearch.TIMESTAMP)
      //   .where("NAME", "==", prevSearch.NAME)
      //   .get()
      //   .then((docs) => {
      //     docs.forEach((doc) => {
      //       tempID = doc.id;
      //       return;
      //     });
      //   });
      const to_delete_ref = doc(db, "luna_results", tempID);
      await updateDoc(to_delete_ref, {
        DELETED: true,
      });

      // db.collection("luna_results").doc(tempID).update({
      //   DELETED: true,
      // });

      this.prevSearches.splice(this.prevSearches.indexOf(prevSearch), 1);
      this.prevSearches.sort((a, b) => b.TIMESTAMP - a.TIMESTAMP);
    },
    // routeLuna(clickedOn, index) {

    // },
    /**
     * navigate between luna output pages
     * @param {*} clickedOn
     */
    async navLuna(clickedOn, index) {
      this.loadingFounders = false;
      this.loadingFoundersError = false;

      this.lunaSearch = "";
      if (clickedOn == "search" || index == -1) {
        this.lunaStage = 0;
        this.wholeStartup = null;
        // if (this.$route.params.companyID != "") {
        //   this.$router.push({
        //     name: "Luna",
        //     params: {
        //       companyID: "",
        //     },
        //   });
        // }
      } else {
        this.wholeStartup = clickedOn;
        await this.checkForUpdates(clickedOn);

        this.dataForModel = clickedOn.DATAFORMODEL;
        this.targetStartup = clickedOn.TARGETSTARTUP;
        this.lunaConfidence = clickedOn.CONFIDENCE;
        this.reloadOutput = false;
        this.reloadOutput = true;
        this.outputKey += 1;

        // this.$router.push(`/${this.searchIDs[index]}`);

        // if (this.$route.params.companyID != this.searchIDs[index]) {
        //   this.$router.push({
        //     name: "Luna",
        //     params: {
        //       companyID: this.searchIDs[index],
        //     },
        //   });
        // }

        this.lunaStage = 4;
      }

      this.currentResult = clickedOn;
    },
    async getInsights(founders) {
      let insights = [];
      for (let f of founders) {
        const options = {
          method: "POST",
          url: "https://go-data-api.fly.dev/luna/insights",
          headers: {
            "Authorization": this.apiKey,
          },
          data: {
            founder: f,
          },
        };
        try {
          const response = await axios.request(options);

          let ins = response.data.response.gpt_skills.join(", ");
          insights.push(ins);
          // return response.data.companies;
        } catch (error) {
          return error;
        }
      }

      return insights;
    },
    async checkForUpdates(clickedOn) {
      try {
        if (!this.wholeStartup.CURRENT_VERSION) {
          this.currentResult = clickedOn;
          this.dataForModel = clickedOn.DATAFORMODEL;
          this.targetStartup = clickedOn.TARGETSTARTUP;
          this.lunaConfidence = clickedOn.CONFIDENCE;
          this.reloadOutput = false;
          this.reloadOutput = true;
          this.outputKey += 1;

          this.lunaStage = 4;

          this.loadingFounders = true;
          // THIS IS WHERE WE ADD THE API
          // let list_of_insights = [
          //   "Capital allocation, Stakeholder management, Strategic partnerships",
          //   "Financial Acumen, People Skill",
          //   "Technical Prowess",
          // ];

          let list_of_insights = await this.getInsights(
            this.wholeStartup.DATAFORMODEL.founder
          );

          const startup_ref = query(
            collection(db, "luna_results"),
            where("USER", "==", this.user.uid),
            where("TIMESTAMP", "==", this.wholeStartup.TIMESTAMP),
            where("NAME", "==", this.wholeStartup.NAME)
          );

          const startup_snapshot = await getDocs(startup_ref);
          let tempID = "";

          startup_snapshot.forEach((doc) => {
            tempID = doc.id;
            return;
          });

          const update_ref = doc(db, "luna_results", tempID);

          await updateDoc(update_ref, {
            FOUNDER_INSIGHTS: list_of_insights,
            CURRENT_VERSION: 1,
          });

          const index = this.prevSearches.findIndex(
            (obj) => obj.LINKEDIN === this.wholeStartup.LINKEDIN
          );

          if (index > -1) {
            this.prevSearches[index].FOUNDER_INSIGHTS = list_of_insights;
            this.prevSearches[index].CURRENT_VERSION = 1;
          }
        }
        this.loadingFounders = false;
      } catch {
        this.loadingFoundersError = true;
        this.loadingFounders = false;
      }
    },
    /**
     * clean linkedin url
     * @param {*} url
     */
    cleanLinkedInUrl(url) {
      var regex1 = /https:\/\/.*?linkedin.com/;
      var clean1 = url.replace(regex1, "https://linkedin.com");

      var regex2 = /(\?.*)/;
      var clean2 = clean1.replace(regex2, "");

      var regex3 = /(\/company\/[^/?#]+)\/.*/;
      var clean3 = clean2;
      if (regex3.test(clean2)) {
        var clean2List = clean2.split("/");
        clean2List.pop();
        clean3 = clean2List.join("/");
      }
      // var clean3 = clean2.replace(regex3, "");
      return clean3;
    },
    /**
     * css focus searchbox when user clicks on it
     * @param {*}
     */
    selectedSearcher() {
      const input = document.getElementById("searchTextBox");
      input.focus();
      // input.select();
    },
    /**
     * API endpoint: search linkedin with a linkedin link
     * @param {*} links
     */
    // go-data-api.fly.dev
    async apiLinkedin(links) {
      const options = {
        method: "POST",
        url: "https://go-data-api.fly.dev/companies/scrape",
        headers: {
          "Authorization": this.apiKey,
        },
        data: {
          company_urls: [links[0].replace("https://", "https://www.")],
        },
      };
      try {
        const response = await axios.request(options);
        return response.data.companies;
      } catch (error) {
        return error;
      }
    },
    /**
     * API endpoint: search linkedin with a keyword
     * @param {string} keyword
     */
    async apiKeyword(keyword) {
      const options = {
        method: "POST",
        url: "https://go-data-api.fly.dev/companies/scrape_links",
        headers: {
          "Authorization": this.apiKey,
        },
        data: {
          search_terms: [keyword],
        },
      };
      try {
        const response = await axios.request(options);
        return response.data;
      } catch (error) {
        return error;
      }
    },
    /**
     * get industry from industry cipher (DEPRECATE THIS)
     * @param {number} industry
     */
    setIndustry(industry) {
      for (var industryIndex in this.industryCipher) {
        if (this.industryCipher[industryIndex].LINKEDIN.includes(industry)) {
          // returnIndustry = industryIndex;
          return industryIndex;
        }
      }
      return -1;
    },
    /**
     * search for one of the examples provided
     * @param {*} link
     */
    submitExample(link) {
      this.lunaSearch = link;
      this.submitSearch();
    },
    /**
     * submit the search from searchbox
     */
    async submitSearch() {
      if (this.lunaSearch == "") {
        this.navLuna("search");
        return;
      }
      logAction(this.lunaSearch, "luna search");
      this.searchResults = Array(6).fill("LOADING");
      this.loadingstage += 1;
      this.lunaStage = 1;
      this.confirmedEditPage = false;
      if (this.lunaSearch.includes("linkedin.com/company/")) {
        this.searchResults = ["LOADING"];
        this.searchResults = await this.apiLinkedin([
          this.cleanLinkedInUrl(this.lunaSearch),
        ]);
      } else {
        let listOfLinks = await this.apiKeyword(this.lunaSearch);
        let index = 0;
        let cleanedUrlList = [];
        // listOfLinks[Object.keys(listOfLinks)[0]]
        for (let dirtyLink of listOfLinks.links[this.lunaSearch]) {
          let cleanedLink = this.cleanLinkedInUrl(dirtyLink);
          if (!cleanedUrlList.includes(cleanedLink)) {
            cleanedUrlList.push(cleanedLink);
          }
        }
        if (cleanedUrlList.length < 6)
          this.searchResults = Array(cleanedUrlList.length).fill("LOADING");
        else this.searchResults = Array(6).fill("LOADING");

        for (let resultLinkedin of cleanedUrlList) {
          let jsonResult = await this.apiLinkedin([resultLinkedin]);
          if (jsonResult[0]) {
            this.searchResults[index] = jsonResult[0];
            // this.searchResults[index].industry_simple.length = 1;
            // if (this.setIndustry(this.searchResults[index].industry_simple[0]) != -1) {
            //   this.searchResults[index].industry_simple.push(
            //     this.setIndustry(this.searchResults[index].industry_simple[0])
            //   );
            // }
            index++;
          }
          if (index == 6) break;
        }
      }
      this.searchResults = this.searchResults.filter((i) => i !== "LOADING");
      this.loadingstage -= 1;
    },
    /**
     * API endpoint: search for a linkedin link
     * @param {*} links
     */
    goToStage(stageindex) {
      this.confirmedEditPage = false;
      this.lunaStage = stageindex;
    },
    /**
     * run model with edited data and go to next stage
     * @param {*} startupFounders
     * @param {*} editableData
     */
    runModel(startupFounders, editableData) {
      this.startupFounders = startupFounders;

      this.targetStartup.stage = editableData.stage;
      this.targetStartup.funding = editableData.funding;
      this.targetStartup.location = editableData.location;
      this.targetStartup.revenue = editableData.revenue;
      this.targetStartup.funding = editableData.funding;
      this.targetStartup.year_founded = editableData.year;
      this.targetStartup.industry = editableData.industry;

      this.lunaStage = 3;
    },
    /**
     * get user data
     * @param {*} user
     */
    async getSaved(user) {
      const user_ref = doc(db, "users", user.uid);
      const user_doc = await getDoc(user_ref);

      if (user_doc.exists()) {
        this.isAdmin = user_doc.data().ADMIN;
        this.apiKey = this.apiKey + user_doc.data().APIKEY;
      }

      const luna_ref = query(
        collection(db, "luna_results"),
        where("USER", "==", user.uid)
      );
      const luna_snapshot = await getDocs(luna_ref);

      luna_snapshot.forEach((doc) => {
        this.prevSearches.push(doc.data());
        this.searchIDs.push(doc.id);
        // console.log(doc.id);
      });

      this.prevSearches.sort((a, b) => b.TIMESTAMP - a.TIMESTAMP);
    },
  },
};
</script>

<style>
/* #mainPage {
  background: white;
  top: 50px;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
} */
.popupBack {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #00000020;
  backdrop-filter: blur(10px);
  z-index: 100;
}
.popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.popupBody {
  position: fixed;
  /* background: white; */
  border: thin #0000002e solid;
  box-shadow: 0 0px 8px 0 #0000001d;
  background: #fefefe;
  padding: 20px;
  border-radius: 10px;
  z-index: 101;
  width: 500px;
  height: 120px;
  left: calc(50% - 260px);
  right: calc(50% - 260px);
  top: calc(50% - 60px);
  bottom: calc(50% - 60px);
}
.deleteActions {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  bottom: 20px;
  right: 20px;
}
.warningTitle {
  font-family: sans-serif;
  font-size: 20px;
  font-weight: bold;
  color: #721cff;
  padding: 5px;
}
.warningText {
  font-family: sans-serif;
  padding: 5px;
}
#lunaNavBox {
  width: 250px;
  top: 0;
  position: absolute;
  left: 0px;
  bottom: 0px;
  /* border-right: thin #e8e8e8 solid; */
  background: #f9f4ff;
  overflow: hidden;
  z-index: 3;
  padding: 0;
}
#tableBox {
  position: absolute;
  top: 8px;
  bottom: 8px;
  right: 8px;
  left: 258px;
  border-radius: 7px;
  background: white;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.loadingFounderCSS {
  position: absolute;
  top: 72px;
  bottom: 9px;
  right: 9px;
  left: 259px;
  border-radius: 0 0 7px 7px;
  background: white;
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.centeredTableBox {
  align-items: center;
  justify-content: center;
}
#searcher {
  border: thin #9595953f solid;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  /* color: grey; */
  cursor: text;
  font-size: 14px;
}
#searcher:hover {
  border: thin #721cff solid;
}
.searcherTop {
  width: 832px;
  margin: auto;
  margin-top: 12px;
  padding: 3px;
  margin-bottom: 12px;
  background: white;
  /* margin-left: -10px; */
  /* transform: translateX(-10px); */
}
.searcherStart {
  width: 504px;
  padding: 10px 12px;
  height: 60px;
  margin-top: 30px;
  flex-wrap: wrap;
  background: #9595951a;
}
.lunaSearchBox {
  border: none;
  font-size: 14px;
  background: transparent;
  /* width: calc(100vw - 451px); */
  width: 710px;
  height: 20px;
  color: black;
  outline: none;
}
.lunaSearchBox::placeholder {
  color: rgb(101, 101, 101);
}
.lunaSearchBoxStart {
  width: 490px;
}
/* .lunaSearchBoxStart:focus {
  background: red;
} */
.selectedSearch {
  border: thin #721cff solid !important;
  /* border: thin solid white !important; */
  /* background: white; */
  transition: ease-in-out 0.2s;
  outline: 3px solid #731cff73;
}
.exampleCompanyGrid {
  display: flex;
  flex-direction: row;
  width: 530px;
  flex-wrap: wrap;
  margin-top: 5px;
  font-size: 15px;
  align-items: center;
  justify-content: space-between;
}
.exampleCompanyText {
  width: 100%;
  padding: 3px;
  padding-top: 10px;
}
.exampleCompany {
  /* border: thin #9595953f solid; */
  /* border: thin #f2f2f2 solid; */
  /* box-shadow: 0px 0px 2px #e0e0e0, 0px 1px 4px -2px #3d3d3d0f,
    0px 4px 4px -2px #3535351c; */
  border-radius: 10px;
  padding: 2px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: calc(25% - 10px);
  margin-top: 4px;
  /* margin: 2px; */
}
.exampleCompany:active {
  margin-top: 5px;
}
.exampleCompany:hover {
  cursor: pointer;
  background: #f3f3f3;
}
.lunaTab {
  max-width: 229px;
  min-width: 229px;
  padding: 5px;
  margin: 1px 2px;
  margin-left: 7px;
  height: 20px;
  display: flex;
  /* margin: 5px; */
  align-items: center;
  justify-content: space-between;
  font-size: 13px;
  cursor: pointer;
  border: thin solid transparent;
}
/* .lunaTab:hover {
  background: #9595953f;
  border: thin solid #9595953f;
} */
.deleteSearch {
  background: #9595953f;
  border: thin solid #9595953f;
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
  display: none;
}
.lunaTab:hover .deleteSearch {
  display: flex;
}
.biglunaTab {
  height: 30px;
  margin: 5px;
  max-width: 221px;
  min-width: 221px;
}
.newSearchBtn {
  margin-left: 5px;
  justify-content: space-between;
}
.selectedTableButton {
  /* border: thin solid #9595953f; */
  background: #9595953f;
}
.selectedTableButton:hover {
  background: #9595953f;
  /* border: thin solid #9595953f; */
}
.roundLogo {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  border: thin solid #9595953f;
  border-radius: 50%;
  overflow: hidden;
}
.lunaView {
  /* padding: 10px; */
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  border-top: thin solid #9595953f;
}
.suggestedCompany {
  margin: 5px 5px 0 0;
  padding: 5px 10px;
  border: thin solid #9595953f;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.suggestedCompany:hover {
  background: #9595953f;
}
.loadingOutline {
  height: 5px;
  width: 100%;
  background: #731cff73;
}
.notifOutline {
  height: 40px;
  width: 100%;
  background: oklch(0.6147 0.16 64.21 / 0.2);
  color: oklch(0.6147 0.16 64.21);
  display: flex;
  align-items: center;
  padding: 0 10px;
}
.greenNotif {
  color: oklch(47.06% 0.17 148.76);
  background: oklch(47.06% 0.17 148.76 / 0.2);
}
.orangeNotif {
  color: oklch(0.6147 0.16 64.21);
  background: oklch(0.6147 0.16 64.21 / 0.2);
}
.redNotif {
  color: oklch(62.8% 0.25 29.23);
  background: oklch(62.8% 0.25 29.23 / 0.2);
}
/* .startupWarning {
  background: oklch(0.6147 0.16 64.21 / 0.2);
}
.startupError {
  background: oklch(62.8% 0.25 29.23 / 0.2);
} */
.loadingLine {
  height: 5px;
  width: 100%;
  /* border-radius: 20px; */
  background: #721cff;
  animation: slider 4s infinite alternate cubic-bezier(1, 0.2, 0.2, 1);
  /* -webkit-animation: slider 2s infinite alternate; */
}
@keyframes slider {
  0% {
    margin-left: 0px;
    width: 20%;
  }
  50% {
    margin-left: 0;
    width: 100%;
  }
  100% {
    margin-left: 80%;
    width: 20%;
  }
}
.loadingTextItem {
  height: 20px;
  width: 100%;
  margin: 1px 5px 1px 10px;
  border-radius: 5px;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background-color: #f6f7f8;
  background: linear-gradient(to right, #eeeeee 20%, #bbbbbb 40%, #eeeeee 60%);
  background-size: 800px 104px;
  position: relative;
}
@keyframes placeHolderShimmer {
  0% {
    background-position: -800px 0;
  }
  100% {
    background-position: 800px 0;
  }
}
.resultListItem {
  /* width: calc(100% - 30px); */
  width: 850px;
  border: thin solid #9595953f;
  border-radius: 7px;
  padding: 10px;
  margin: 5px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 13px;
  cursor: pointer;
}
.resultListItem:hover {
  background: #9595953f;
}
.hoverButton {
  position: absolute;
  /* right: px; */
  transform: translate(675px, 0);
  display: none;
  padding-right: 2px;
  padding-left: 10px;
}
.resultListItem:hover .hoverButton {
  display: flex;
}
.notAllowed {
  cursor: not-allowed !important;
}
.notAllowed:hover .hoverButton {
  display: none;
}
.resultItem {
  border-right: thin solid #9595953f;
  display: flex;
  align-items: center;
  padding: 0 10px;
  overflow: hidden;
}
.resultText {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: bold;
  text-decoration: underline;
}
.suggestedLogo {
  border: thin solid #9595953f;
  border-radius: 5px;
  /* background: green; */
  height: 20px;
  min-width: 20px;
  margin-right: 10px;
}
.resultFounder {
  border: thin solid #9595953f;
  border-radius: 5px;
  padding: 2px 5px;
  margin-right: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.applyInput:hover {
  border-color: #721cff !important;
}
.errorOutline {
  border-color: #721cff !important;
  /* background: #731cff73; */
  background: oklch(0.6147 0.16 64.21 / 0.2);
}
.lunaHeaderItem {
  padding-bottom: 10px;
}
.lunaMain {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.lunaMainResults {
  /* width: 100%; */
  width: 840px;
  padding: 10px 0;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  /* flex-direction: column; */
  /* border-bottom: thin solid #9595953f; */
}
.lunaResultNum {
  border: thin solid #9595953f;
  background: #95959511;
  border-radius: 10px;
  padding: 20px;
  height: 90px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  margin-right: 3px;
}
.blueBox {
  border: thin solid #721cff;
  background: #721cff3f;
}
.redBox {
  background: oklch(62.8% 0.25 29.23 / 0.4);
  border: thin solid oklch(62.8% 0.25 29.23);
}
.greenBox {
  background: oklch(47.06% 0.17 148.76 / 0.4);
  border: thin solid oklch(47.06% 0.17 148.76);
}
.resultFont {
  font-size: 25px;
  /* color: green; */
  color: black;
  /* font-weight: bold; */
}
.hoverLink {
  cursor: pointer;
  /* text-decoration: underline; */
  border: thin solid #9595953f;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 7px;
  width: fit-content;
  border-radius: 5px;
  margin-right: 3px;
}
.hoverLink:hover {
  background: #9595953f;
}
.selectFounderButtonExternal {
  border-radius: 50%;
  width: 27px;
  height: 27px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}
.selectFounderButtonExternal:hover {
  background: #731cff37;
}
.selectedFounderButtonInternal {
  border-radius: 50%;
  width: 20px;
  height: 20px;
  background: #731cff72;
  border: #731cff thin solid;
  display: flex;
  align-items: center;
  justify-content: center;
}
.selectFounderButtonInternal {
  border-radius: 50%;
  width: 20px;
  height: 20px;
  /* background: #731cff72; */
  border: #731cff thin solid;
}
.clickableName {
  font-weight: bold;
  margin-bottom: 5px;
  border-bottom: thin solid transparent;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  width: fit-content;
}
.clickableName:hover {
  border-bottom: thin solid #721cff;
  color: #721cff;
  width: fit-content;
}
.clickFill {
  fill: black;
  display: none;
}
.clickableName:hover .clickFill {
  fill: #721cff;
  display: block;
}
.blockTitle {
  margin: 0;
  padding: 5px;
  border-radius: 12px;
  border: thin solid #9595953f;
  color: black;
  font-size: 14px;
  width: 830px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 20px;
}
.lunaNavScroll {
  height: calc(100vh - 170px);
  padding-bottom: 10px;
  /* background: red; */
  overflow-y: auto;
  overflow-x: hidden;
}
</style>
